<template>
    <div class="loading-overlay">
        <div class="loading-spinner">
            <div class="spinner"></div>
            <span class="loading-text">Carregando...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loading",
    }
</script>

<style lang="scss" scoped>
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .loading-spinner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .spinner {
        width: 50px;
        height: 50px;
        border: 5px solid $gray-line;
        border-top: 5px solid $gray-primary;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    .loading-text {
        margin-top: 15px;
        color: $gray-primary;
        font-size: 16px;
        font-weight: 500;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>